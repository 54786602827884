import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conditionalLowercase'
})
export class ConditionalLowercasePipe implements PipeTransform {

//   transform(value: unknown, ...args: unknown[]): unknown {
//     return null;
//   }

// }
transform(value: string): string {
  // Check if the value is defined
  if (!value) return value;

  // Convert the entire string to lowercase
  let lowercasedValue = value.toLowerCase();

  // Check for "Site A" and "Site Z" and Service ID and replace them with their original case
  if (lowercasedValue.includes("site a")) {
    lowercasedValue = lowercasedValue.replace("site a", "Site A");
  }
  if (lowercasedValue.includes("site z")) {
    lowercasedValue = lowercasedValue.replace("site z", "Site Z");
  }
  if (lowercasedValue.includes("service id")){
    lowercasedValue = lowercasedValue.replace("service id", "service ID");
  }

  return lowercasedValue; // Return the modified string
}
}
