<div class="custom-arrow-up"></div>
<div class="custom-filter">
  <h3>{{customFilterObj?.fullHeaderName}}
    <a 
      class="closefilterclass" 
      tabindex="0" 
      aria-label="click to close filter" 
      (keypress)="closeFilter('keypress')" 
      (click)="closeFilter(null)"><i class="icon-icon-ui-cross icon-xs closeicon"></i></a>
  </h3>
  <form 
    #dateForm="ngForm" 
    (submit)="onApplyFilters()">
    <div class="container">
      <label 
        class="filter-type" 
        for="from_date">Filter by {{customFilterObj?.fullHeaderName | lowercase}}
      </label>
      <div class="row datepicker-container">
        <div class="col-5 dpFrom">
          <app-cui-datepicker 
            [datePlaceholder]="'Start Date'" 
            aria-label="fromDatePickerFilter"
            [datePickerId]="'from_date'" 
            [modelDate] = "fromFilterDate" 
            [maxDate]= "frommaxDate"
            (dateSelected)="onChangeFromDate($event)" 
            [navigation]= "false"
            [model]="fromDatePickerFilter">
          </app-cui-datepicker>
        </div>
        <div class="col-2 spn-to"><span>to</span></div>
        <div  
          class="col-5 dpTo" 
          [ngClass]="{'disableWrapper': !isfromDate}">
          <app-cui-datepicker 
            id="tocustomFilterDate" 
            [ngClass]="{'disabledDate': !isfromDate}"
            aria-label="toDatePickerFilter"
            [datePlaceholder]="'End Date'"   
            [modelDate] = "toFilterDate" 
            [datePickerId]="to_date" 
            [minDate]= "tominDate" 
            [navigation]= "false" 
            [model]="toDatePickerFilter"
            (dateSelected)="onChangetoDate($event)">
          </app-cui-datepicker>
        </div>
      </div>
      <button 
        id="btnApply" tabindex="0"
        [disabled]="!istoDate || !isfromDate" 
        aria-label="apply filter" 
        class="secondary-btn applyBtn" 
        (click)="onApplyFilters()">Apply filter/s
      </button>
    </div>
  </form>
</div>
