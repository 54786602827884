import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxFilterComponent } from './checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from './custom-date-filter/custom-date-filter.component';
import { CustomTextFilterComponent } from './custom-text-filter/custom-text-filter.component';
import { FormsModule } from '@angular/forms';
import { TwCoreUIFormModule } from 'tw-core-ui';
import { ConditionalLowercasePipe } from './pipe/conditional-lowercase.pipe';



@NgModule({
  declarations: [CheckboxFilterComponent, CustomDateFilterComponent, CustomTextFilterComponent, ConditionalLowercasePipe],
  imports: [
    CommonModule,
    FormsModule,
    TwCoreUIFormModule
  ],
  exports: [
    CustomTextFilterComponent,
    CheckboxFilterComponent,
    CustomDateFilterComponent
  ],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})
export class GridFilterModule { }
